import Partials from 'components/partials/Partials';
import type { BasisPageData } from 'types/cms';
import { toKebabCase } from 'utils/string';

const IndexPage = ({ context }: { context: BasisPageData }) => (
  <>
    {context?.areas?.main?.map((widget, index) => {
      const contextId = widget.id ?? toKebabCase(widget.title) + index;
      return <Partials key={contextId} context={widget} />;
    })}
  </>
);

export default IndexPage;
